import axios from '@/helpers/axios';
import Core from '@/core/module';

export default class VacatureBadge extends Core {
    init() {
        this.$el.textContent = '15';

        axios
            .get('https://werkenbijtielbeke.nl/jobCount')

            .then((response) => {
                this.$el.textContent = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    }
}
