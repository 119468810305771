import ModuleAjaxform from '@/modules/ajaxform';

export default class AjaxForm extends ModuleAjaxform {
    onSuccess(data) {
        if (!data) {
            return;
        }

        if (data.message) {
            this.$el.reset();

            const $messageEl = this.$el.parentElement.querySelector(`[data-role=message][for=${this.$el.name}]`);

            if ($messageEl) {
                $messageEl.style.display = 'flex';

                setTimeout(() => {
                    $messageEl.classList.add('contact-form-message--show');
                }, 50);

                setTimeout(() => {
                    $messageEl.classList.remove('contact-form-message--show');
                }, 5000);
            }

            return;
        }

        if (data && data.url) {
            window.location = data.url;
        }
    }
}
