import Core from '@/core/module';

export default class backtotop extends Core {
    init() {
        this.offset = 300;
        this.addEventListeners();
    }

    addEventListeners() {
        window.addEventListener('scroll', () => {
            const scrollTop = window.scrollY;

            if (scrollTop > this.offset) {
                this.$el.classList.add('backtotop--show');
            } else {
                this.$el.classList.remove('backtotop--show');
            }
        });

        this.$el.addEventListener('click', (e) => {
            e.preventDefault();

            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
    }
}
