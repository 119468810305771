// import ScrollTrigger from '@terwanerik/scrolltrigger';
import gsap from 'gsap';
import Core from '@/core/module';

export default class RedLine extends Core {
    init() {
        this.timeOut = null;
        this.resize();

        this.firstMarker = document.querySelector('.redline-marker--first');
        this.lastMarker = document.querySelector('.redline-marker--last');
        this.bullet = document.querySelector('.redline__bullet');
        this.scrollBullet = document.querySelector('.redline__scroll-bullet');
        this.markers = document.querySelectorAll('.redline-marker');

        this.bullet.style.display = 'none';
        this.bulletArray = [];

        [].forEach.call(this.markers, () => {
            const clone = this.bullet.cloneNode(true);
            this.$el.appendChild(clone);
            this.bulletArray.push(clone);
        });

        window.addEventListener('resize', () => {
            clearTimeout(this.timeOut);
            this.resize();
        });
    }

    // addAnimationToggle() {
    //     const redLineBullet = new ScrollTrigger({
    //         trigger: {
    //             once: true,
    //             toggle: {
    //                 class: {
    //                     in: 'redlineBulletIn',
    //                     out: 'redlineBulletOut',
    //                 },
    //             },
    //         },
    //     });
    //     redLineBullet.add('.redline__bullet');
    // }

    setRedline() {
        const firstMarkerYOffset = this.YOffset(this.firstMarker);
        const lastMarkerYOffset = this.YOffset(this.lastMarker);
        const redlineHeight = lastMarkerYOffset - firstMarkerYOffset;
        this.scrollBullet.classList.add('fixed');

        this.$el.style.top = `${firstMarkerYOffset}px`;
        this.$el.style.height = `${redlineHeight}px`;

        [].forEach.call(this.markers, (marker, i) => {
            const bulletYOffset = this.YOffset(marker) - firstMarkerYOffset - 12;

            this.bulletArray[i].style.top = `${bulletYOffset}px`;
            this.bulletArray[i].style.display = 'flex';
        });

        this.scrollBullet.classList.add('fixed');
        this.scrollBullet.style.top = `${firstMarkerYOffset - 14}px`;

        gsap.fromTo(this.scrollBullet, {
            opacity: 0,
            top: firstMarkerYOffset - 50,
        }, {
            opacity: 1,
            top: firstMarkerYOffset - 14,
            duration: 1,
            ease: 'expo.out',
        });

        gsap.fromTo(this.$el, {
            opacity: 0,
            top: firstMarkerYOffset - 50,
        }, {
            opacity: 1,
            top: firstMarkerYOffset,
            duration: 1,
            ease: 'expo.out',
        });

        window.addEventListener('scroll', () => {
            if (document.documentElement.scrollTop >= redlineHeight) {
                this.scrollBullet.style.top = `${redlineHeight - 14}px`;
                this.scrollBullet.classList.remove('fixed');
            } else {
                this.scrollBullet.style.top = `${firstMarkerYOffset - 14}px`;
                this.scrollBullet.classList.add('fixed');
            }
        });

        // window.addEventListener('scroll', () => {
        //     if (document.documentElement.scrollTop >= redlineHeight) {
        //         this.scrollBullet.style.top = `${redlineHeight - 14}px`;
        //     } else {
        //         this.scrollBullet.style.top = `${document.documentElement.scrollTop - 14}px`;
        //     }

        //     // if (document.documentElement.scrollTop < redlineHeight) {
        //     //     this.scrollBullet.style.top = `${document.documentElement.scrollTop - 14}px`;
        //     //     //this.scrollBullet.classList.remove('rotate');
        //     // } else {
        //     //     this.scrollBullet.style.top = `${redlineHeight - 14}px`;
        //     // }

        //     // gsap.set(this.scrollBullet, {
        //     //     y: yPos,
        //     // });
        // });
    }

    // scroll() {
    //     this.scrollBullet.style.top = `${document.documentElement.scrollTop}px`;
    //     // gsap.to(this.scrollBullet, {
    //     //     y: document.documentElement.scrollTop,
    //     //     duration: 0.4,
    //     // });
    // }

    resize() {
        this.timeOut = setTimeout(() => {
            this.setRedline();
            // this.addAnimationToggle();
        }, 2000);
    }

    YOffset(el) {
        const rect = el.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return rect.top + scrollTop;
    }
}
