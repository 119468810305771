import Core from '@/core/module';

export default class Faq extends Core {
    init() {
        this.addEventListeners();
    }

    addEventListeners() {
        document.querySelectorAll('.faq__title').forEach(($el) => {
            const $container = $el.parentNode;
            const $content = $container.querySelector('.faq__content');

            const closeEvent = () => {
                $content.style.height = 'auto';
                $content.removeEventListener('transitionend', closeEvent);
            };

            $el.addEventListener('click', () => {
                const height = $content.scrollHeight;

                $content.removeEventListener('transitionend', closeEvent);

                $container.classList.toggle('faq__item--open');

                if ($container.classList.contains('faq__item--open')) {
                    $content.style.height = `${height}px`;

                    $content.addEventListener('transitionend', closeEvent);
                } else {
                    $content.style.height = `${height}px`;

                    setTimeout(() => {
                        $content.style.height = '0px';
                    }, 20);
                }
            });
        });
    }
}
