import 'dom4';
// import Scrollbar from 'smooth-scrollbar';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Router from '@/core/router';
import ModuleAjaxformContact from '@/modules/ajaxform/contact';
import ModuleFAQ from '@/modules/faq';
import ModuleBacktotop from '@/modules/backtotop';
import ModuleMobileMenu from '@/modules/mobilemenu';
import ModuleTextSlider from '@/modules/textslider';
import ModuleScrollElements from '@/modules/scrollElements';
import ModuleVacatureBadge from '@/modules/vacaturebadge';
import ModuleRedLine from '@/modules/redline';
import '../../sass/index.scss';

const router = new Router();

router.setup({
    options: {
        pageContainer: '.site-container',
        transition: 'slide',
        historyTransition: 'slide',
        ajaxLoading: true,
        imagePreloading: false,
    },
    modules: [
        {
            class: ModuleScrollElements,
            element: 'body',
        },
        {
            class: ModuleRedLine,
            element: '.redline',
            single: true,
        },
        {
            class: ModuleMobileMenu,
            element: '.topbar-mobile',
            single: true,
        },
        {
            class: ModuleBacktotop,
            element: '.backtotop',
            single: true,
        },
        {
            class: ModuleFAQ,
            element: '.faq',
        },
        {
            class: ModuleTextSlider,
            element: '.ubrchecks',
            single: true,
        },
        {
            class: ModuleVacatureBadge,
            element: '.footer__nav-badge',
            single: true,
        },
        {
            class: ModuleAjaxformContact,
            element: '[ajaxform-contact]',
        }/*,
        {
            load: () => import(/* webpackChunkName: "cookieconsent" *//*'@/modules/cookie'),
            element: '#cookieconsent',
        }*/,
    ],
});
