export default class Events {
    constructor() {
        this.events = [];
    }

    bind(rootObject) {
        if (typeof rootObject === 'object') {
            Object.assign(rootObject, {
                events: this.events,
                on: this.on,
                off: this.off,
                trigger: this.trigger,
            });
        }
    }

    on(eventNames, eventFunction) {
        const eventNamesArray = eventNames.split(' ');

        eventNamesArray.forEach((value) => {
            const cleanValue = value.trim();
            const [eventName] = cleanValue.split('.');
            const eventClass = cleanValue.substring(
                cleanValue.indexOf('.') + 1,
                cleanValue.length,
            );
            const event = { eventName, eventClass, eventFunction };

            this.events.push(event);
        });

        return this;
    }

    off(_eventNames) {
        // remove all events
        if (!_eventNames) {
            this.events = [];
            return this;
        }

        const eventsArray = _eventNames.split(' ');

        eventsArray.forEach((value) => {
            const eventItem = value.trim();

            const eventNameSplitted = eventItem.split('.');
            const [eventName] = eventNameSplitted;
            const eventClass = eventItem.substring(
                eventItem.indexOf('.') + 1,
                eventItem.length,
            );

            this.events = this.events.filter((event) => {
                if (
                    event.eventName === eventName &&
                    (!eventClass || (eventClass && event.eventClass === eventClass))
                ) {
                    return false;
                }

                return true;
            });
        });

        return this;
    }

    trigger(eventNames, data, resolve, reject) {
        // get events and split them by ,
        const splittedEvents = eventNames.split(',');

        // loop events
        splittedEvents.forEach((value) => {
            const event = value.trim();

            // split event by . for class name
            const [eventName] = event.split('.');
            const [, eventClass] = event.split('.');

            const events = this.events.filter((eventValue) => {
                if (
                    eventValue.eventName === eventName &&
                    (!eventClass || (eventClass && eventValue.eventClass === eventClass))
                ) {
                    return true;
                }

                return false;
            });

            // set prevented to false, wait before a on is pausing original function
            let prevented = false;

            events.forEach((preventValue) => {
                // generate event object for preventDefault
                const e = {
                    preventDefault: () => {
                        prevented = true;
                    },
                };

                // call on event
                preventValue.eventFunction(e, data);
            });

            // check if some on event is blocked callback with preventDefault
            if (!prevented && typeof resolve === 'function') {
                resolve();
            } else if (prevented && typeof reject === 'function') {
                reject();
            }
        });

        return this;
    }
}
