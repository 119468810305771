require("babel-polyfill");

export default class Transitions {
    async load(transitionOptions) {
        const TransitionClass = await import(`./${transitionOptions.transition}`);
        const Transition = TransitionClass.default;
        this.transition = new Transition(transitionOptions);
    }

    async animateIn() {
        if (typeof this.transition.animateIn !== 'function') {
            console.error(`
                function animateIn not available in /core/router/transitions/${this.transitionType}/index.js
            `);

            return null;
        }

        await this.transition.animateIn();

        window.scrollTo(0, 0);

        return null;
    }

    async animateOut() {
        if (typeof this.transition.animateOut !== 'function') {
            console.error(`
                function animateOut not available in /core/router/transitions/${this.transitionType}/index.js
            `);

            return null;
        }

        await this.transition.animateOut();

        this.transition.$el.parentNode.removeChild(this.transition.$el);

        return null;
    }

    async destroy() {
        if (typeof this.transition.destroy !== 'function') {
            console.error(`
                function destroy not available in /core/router/transitions/${this.transitionType}/index.js
            `);

            return null;
        }

        await this.transition.destroy();

        this.transition.$el.parentNode.removeChild(this.transition.$el);

        return null;
    }
}
