import axios from '@/helpers/axios';
import Core from '@/core/module';
import GA from '@/helpers/ga';

export default class Ajaxform extends Core {
    init() {
        this.setDefaults({
            close: '.close',
            defaultTimeout: 10,
            scrollToError: true,
            tracking: true,
            preventDefault: true,
            formname: (this.$el.getAttribute('form-name')) || 'unnamed form',
            scrollContainer: 'body, html',
        });

        this.loadStatusCount = 0;

        this.addEventListeners();
    }

    addLoadStatus() {
        this.loadStatusCount += 1;

        this.loading = true;

        window.onbeforeunload = function onbeforeunload() {
            return true;
        };

        this.$el.classList.add('form--loading');
    }

    removeLoadStatus() {
        this.loadStatusCount -= 1;

        window.onbeforeunload = null;

        if (this.loadStatusCount <= 0) {
            this.loading = false;
            this.$el.classList.remove('form--loading');
        } else {
            this.loading = true;
            this.$el.classList.add('form--loading');
        }
    }

    submit() {
        if (this.loading) {
            return;
        }

        this.addLoadStatus();
        this.removeAllErrors();

        const formData = new FormData(this.$el);
        formData.append('_doubleCheck', window.siteOptions.websiteID);

        axios
            .post(this.$el.getAttribute('action'), formData)
            .then((response) => {
                const { data } = response;

                this.removeLoadStatus();

                this.trigger('success', data, () => {
                    this.onSuccess(data);
                });

                GA.event({
                    category: `ajaxform ${this.options.formname}`,
                    action: 'send',
                    label: 'successfull',
                });
            })
            .catch((error) => {
                const { response } = error;
                this.removeLoadStatus();

                // check if ajax is aborted
                if (response.status === 0) {
                    return;
                }
                if (response.status === 422) {
                    this.trigger('error', response.data.errors, () => {
                        this.onError('ajax', { errors: response.data.errors });
                    });

                    GA.event({
                        category: `ajaxform ${this.options.formname}`,
                        action: 'send',
                        label: 'field error',
                    });

                    return;
                }

                this.trigger('fatalError', response, () => {
                    this.onError('xhr', response);
                });

                GA.event({
                    category: `ajaxform ${this.options.formname}`,
                    action: 'send',
                    label: `ajax error ${response.status} ${response.statusText}`,
                });
            });
    }

    onSuccess(data) {
        if (data && data.url) {
            window.location = data.url;
        }
    }

    onError(type, error) {
        if (type === 'xhr') {
            alert(`error: ${error.status}`);
        } else if (type === 'ajax') {
            this.renderErrors(error.errors);
        }
    }

    removeAllErrors() {
        const $errorItems = this.$el.querySelectorAll('li[for]');
        $errorItems.forEach(($errorItem) => {
            $errorItem.parentNode.removeChild($errorItem);
        });

        const $errorLists = this.$el.querySelectorAll('ul[for]');
        $errorLists.forEach(($errorList) => {
            $errorList.style.display = 'none';
        });
    }

    renderErrors(errors) {
        this.removeAllErrors();

        errors.forEach((error) => {
            if (error) {
                let $uls = this.$el.querySelectorAll(`ul[for~="${error.inputName}"]`);

                if ($uls.length === 0) {
                    $uls = this.$el.querySelectorAll('ul[for="__empty"]');
                }

                $uls.forEach(($ul) => {
                    const $error = document.createElement('li');
                    $error.innerText = error.message;
                    $error.setAttribute('for', error.inputName);

                    $ul.appendChild($error);

                    $ul.style.display = 'block';
                });
            }
        });

        if (this.options.scrollToError) {
            setTimeout(() => {
                this.scrollToError();
            }, 0);
        }
    }

    scrollToError() {
        const $errorEl = this.$el.querySelector('ul[for] li[for]');

        if ($errorEl) {
            const bounding = $errorEl.getBoundingClientRect();

            window.scrollTo({
                top: (bounding.top + (window.scrollY || window.pageYOffset)) - 200,
                behavior: 'smooth',
            });
        }
    }

    toggleErrorGroups() {
        const $errorGroups = this.$el.querySelectorAll('ul[for]');

        $errorGroups.forEach(($errorGroup) => {
            const $errors = $errorGroup.querySelectorAll('li');

            $errorGroup.style.display = $errors.length > 0 ? 'block' : 'none';
        });
    }

    addEventListeners() {
        if (this.options.preventDefault) {
            this.$el
                .addEventListener('submit', (e) => {
                    e.preventDefault();

                    this.trigger('submit', {}, () => {
                        this.submit();
                    });
                });
        }

        this.$el
            .addEventListener('focusin', (e) => {
                if (e.target && e.target.name) {
                    this.hideError(e.target.name);
                }
            });
    }

    hideError(name) {
        const $errors = this.$el.querySelectorAll(`li[for="${name}"]`);
        $errors.forEach(($error) => {
            $error.parentNode.removeChild($error);
        });

        this.toggleErrorGroups();
    }
}
