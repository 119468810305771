import ScrollTrigger from '@terwanerik/scrolltrigger';
import gsap from 'gsap';
import Core from '@/core/module';

export default class Scrolling extends Core {
    init() {
        const triggerLeft = new ScrollTrigger({
            trigger: {
                once: true,
                toggle: {
                    class: {
                        in: 'slideInLeft',
                        out: 'slideOutLeft',
                    },
                },
            },
        });

        const triggerRight = new ScrollTrigger({
            trigger: {
                once: true,
                toggle: {
                    class: {
                        in: 'slideInRight',
                        out: 'slideOutRight',
                    },
                },
            },
        });

        const triggerUp = new ScrollTrigger({
            trigger: {
                once: true,
                toggle: {
                    class: {
                        in: 'slideInUp',
                        out: 'slideOutUp',
                    },
                },
            },
        });

        const zoom = new ScrollTrigger({
            trigger: {
                once: true,
                toggle: {
                    class: {
                        in: 'zoomOut',
                        out: 'zoomIn',
                    },
                },
            },
        });

        triggerLeft.add('[data-triggerLeft]');
        triggerRight.add('[data-triggerRight]');
        triggerUp.add('[data-triggerUp]');
        zoom.add('[data-zoom]');


        window.addEventListener('scroll', () => {
            if (document.querySelector('[data-headerzoom]')) {
                this.scroll();
            }
        });
    }

    scroll() {
        let count = 0;

        if (window.innerWidth < 800) {
            count = 1 + Math.min(0.8, (0.8 * (window.scrollY / window.innerHeight)));
        } else {
            count = 1 + Math.min(0.1, (0.2 * (window.scrollY / window.innerHeight)));
        }

        gsap.to('[data-headerzoom]', {
            duration: 0.4,
            scale: count,
        });
    }
}
