import axios from 'axios';
import Events from '@/helpers/events';
import Pageloader from '@/modules/pageloader';
import TransitionController from './transitions';

const { CancelToken } = axios;

export default class Router {
    constructor() {
        const events = new Events();
        events.bind(this);

        this.options = {
            pageContainer: '.site-container',
            transition: 'page',
            historyTransition: 'page',
            ajaxLoading: true,
            reloadLinks: ['mailto', 'tel'],
        };

        this.cancelTokenSource = null;

        // variable for control key is pressed
        this.CTRL_PRESSED = false;

        this.modules = [];
        this.runningModules = [];

        this.loading = false;

        this.popped = ('state' in window.history && window.history.state !== null);
        this.initialURL = window.location.pathname;
        this.currentPathname = this.initialURL;
        this.ajaxRequest = null;
    }

    setup(data) {
        Object.assign(this.options, data.options);
        this.addModules(data.modules);

        // Disabled page transitions on iPhones and iPads due to issues with going back to previous page by swiping
        if ((/iPad|iPhone|iPod/.test(navigator.userAgent))) {
            this.options.ajaxLoading = false;
        }

        if (this.options.ajaxLoading) {
            this.addAjaxLoadingEvents();
        }
    }

    addModules(modules) {
        this.modules = [this.modules, ...modules];

        this.fireModules();
    }

    fireModules() {
        this.off('afterLoad');

        this.destroyModules();

        this.modules.forEach((module) => {
            if (module.element) {
                const $element = document.querySelectorAll(module.element);

                if ($element.length > 0) {
                    $element.forEach((el) => {
                        this.fireModule(el, module);
                    });
                }
            } else {
                this.fireModule(document, module);
            }
        });
    }

    fireModule(element, module) {
        if (typeof module.class === 'function') {
            const ModuleClass = module.class;
            const createdModule = new ModuleClass(element, module.options);

            this.runningModules.push(createdModule);
        } else if (typeof module.load === 'function') {
            (async () => {
                const LoadedModule = await module.load();
                const ModuleClass = LoadedModule.default;
                const createdModule = new ModuleClass(element, module.options);
                this.runningModules.push(createdModule);
            })();
        }
    }

    destroyModules() {
        this.trigger('destroy');

        this.runningModules.forEach((module) => {
            if (typeof module.removeEventListeners === 'function' && module.$el) {
                module.removeEventListeners();
            }
        });

        this.runningModules = [];
    }

    addAjaxLoadingEvents() {
        // check if someone is changing browser history by prev/next button or backspace
        window.addEventListener('popstate', () => {
            const initialPop = !this.popped && location.href === this.initialURL;
            this.popped = true;

            if (initialPop) return;

            const { pathname } = document.location;

            // check if hash is changed
            if (pathname === this.currentPathname) {
                // dont refresh page is hash is changed
                return;
            }

            // set current pathname
            this.loadPage(pathname, {
                transition: this.options.historyTransition,
            });
        });

        document.addEventListener('click', (e) => {
            let $clickElement = null;

            if (e.target.tagName === 'A') {
                $clickElement = e.target;
            } else if (e.target.closest('a')) {
                $clickElement = e.target.closest('a');
            }

            if ($clickElement) {
                const href = $clickElement.getAttribute('href');

                if (
                    $clickElement.getAttribute('target') !== '_blank' &&
                    href.substring(0, 1) === '/' && this.CTRL_PRESSED === false &&
                    !href.includes('.pdf') &&
                    !href.includes('.webp') &&
                    !href.includes('.jpg') &&
                    !href.includes('.png')
                ) {
                    e.preventDefault();
                    this.loadPage(href, {
                        $clickElement,
                        transition: $clickElement.getAttribute('transition-name') || this.options.transition,
                    });
                    history.pushState(true, null, href);
                }
            }
        });

        // check if control key is pressed
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 91 || e.keyCode === 17) {
                this.CTRL_PRESSED = true;
            }
        });

        document.addEventListener('keyup', () => {
            this.CTRL_PRESSED = false;
        });

        window.addEventListener('blur', () => {
            this.CTRL_PRESSED = false;
        });
    }

    async loadPage(href, options = {}) {
        const transition = new TransitionController();
        await transition.load(options);

        this.popped = true;
        this.currentPathname = href;

        this.trigger('unload');

        Pageloader.start();

        Promise.all([
            this.loadPageAjax(href),
            transition.animateIn(),
        ]).then(async (result) => {
            // replace old sitecontainer with new sitecontainer
            const $siteContainer = document.querySelector(this.options.pageContainer);
            const $containerElement = document.createElement('DIV');
            $containerElement.innerHTML = result[0].data;
            const $newSiteContainer = $containerElement.querySelector(this.options.pageContainer);

            $siteContainer.parentNode.replaceChild($newSiteContainer, $siteContainer);

            const data = {
                title: $containerElement.querySelector('title').innerText,
            };

            // set new document title
            document.querySelector('title').innerText = data.title;

            // fire all modules
            this.fireModules();

            setTimeout(() => {
                Pageloader.end(() => {
                    Pageloader.hide();
                });
                transition.animateOut();
            });
        }).catch(() => {
            transition.destroy();
        });
    }

    loadPageAjax(href) {
        if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel();
        }

        this.cancelTokenSource = CancelToken.source();

        return axios.get(href, {
            cancelToken: this.cancelTokenSource.token,
            headers: {
                Accept: 'text/html',
            },
        }).catch((response) => {
            if (!axios.isCancel(response)) {
                window.location = href;
            }

            // window.location = href;
            throw new Error();
        });
    }

    updateModule(moduleName) {
        this.modules.forEach((module) => {
            if (moduleName === module.name) {
                const ModuleClass = module.class;
                const runningModule = new ModuleClass(module.element, module.options);

                this.runningModules.push(runningModule);
            }
        });
    }
}
