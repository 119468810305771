import gsap from 'gsap';
import Core from '@/core/module';

export default class MobileMenu extends Core {
    init() {
        this.isOpen = false;
        this.addEventListeners();

        this.tl = gsap.timeline({
            paused: true,
        });

        this.tl
            .to('.topbar-mobile__menu-wrapper .nav-wrapper', { x: 0, duration: 0.01 })
            .add([
                gsap.to('.topbar-mobile__menu-wrapper', {
                    duration: 0.4,
                    height: window.innerHeight,
                }),
                gsap.to('.topbar-mobile__menu-overlay', {
                    duration: 0.2,
                    autoAlpha: 1,
                }),
            ])
            .fromTo('.topbar-mobile__menu-wrapper .nav-primary > li', {
                duration: 0.4,
                y: 20,
            }, {
                autoAlpha: 1,
                y: 0,
                ease: 'back.out(1.7)',
                stagger: 0.05,
            }, '-=0.15')
            .fromTo('.topbar-mobile__menu-wrapper .nav-secondary > li', {
                duration: 0.4,
                y: 20,
            }, {
                autoAlpha: 1,
                y: 0,
                ease: 'back.out(1.7)',
                stagger: 0.05,
            }, '-=0.4');
    }

    addEventListeners() {
        this.$el.querySelector('.topbar-mobile__btn--menu-toggle').addEventListener('click', (e) => {
            e.preventDefault();
            this.toggle();
        });

        this.$el.querySelector('.topbar-mobile__menu-overlay').addEventListener('click', (e) => {
            e.preventDefault();
            this.close();
        });

        const subs = this.$el.querySelectorAll('.has-subnav');
        const subsToggle = this.$el.querySelectorAll('.has-subnav > span');

        for (let i = 0; i < subs.length; i++) {
            subsToggle[i].addEventListener('click', (e) => {
                e.preventDefault();
                this.toggleSubnav(subs[i]);
            });
        }
    }

    toggleSubnav(el) {
        const $subnavContainer = el.querySelector('.subnav');
        const $subnav = $subnavContainer.querySelector('.subnav__nav');
        const $icon = el.querySelector('.icon-arrow-down');

        if (el.classList.contains('show-subnav')) {
            el.classList.remove('show-subnav');

            gsap.to($subnav, {
                autoAlpha: 0,
                y: 10,
            });

            gsap.to($icon, {
                duration: 0.2,
                scaleY: 1,
            });

            gsap.fromTo('.topbar-mobile__menu-wrapper .nav-secondary', {
                autoAlpha: 0,
            }, {
                duration: 1,
                autoAlpha: 1,
            });

            return;
        }

        el.classList.add('show-subnav');

        gsap.to($icon, {
            duration: 0.2,
            scaleY: -1,
        });

        gsap.fromTo($subnav, {
            autoAlpha: 0,
            y: 10,
        }, {
            autoAlpha: 1,
            y: 0,
        });

        gsap.fromTo('.topbar-mobile__menu-wrapper .nav-secondary', {
            autoAlpha: 0,
        }, {
            duration: 1,
            autoAlpha: 1,
        });
    }

    toggle() {
        if (this.isOpen) {
            this.close();
            this.isOpen = false;
        } else {
            this.open();
            this.isOpen = true;
        }
    }

    open() {
        if (this.isOpen) {
            return;
        }

        this.tl.play();

        this.isOpen = true;
        this.$el.querySelector('.hamburger-icon').classList.add('show');
    }

    close() {
        if (!this.isOpen) {
            return;
        }

        this.tl.reverse();

        this.isOpen = false;
        this.$el.querySelector('.hamburger-icon').classList.remove('show');
    }
}
