import gsap from 'gsap';
import Core from '@/core/module';

export default class TextSlider extends Core {
    init() {
        this.tl = gsap.timeline({ repeat: -1 });

        this.list = [];

        this.$el.querySelectorAll('li').forEach((el) => {
            this.list.push(el);
        });

        this.addEventListeners();
    }

    addEventListeners() {
        window.addEventListener('resize', () => {
            this.tl.clear();

            if (window.innerWidth < 1068) {
                this.marquee(this.list);
            }
        });

        window.dispatchEvent(new Event('resize'));
    }

    marquee(list) {
        for (let i = 0; i < list.length; i++) {
            this.tl
                .fromTo(list[i], {
                    opacity: 0,
                    duration: 0.5,
                }, {
                    opacity: 1,
                })
                .to({}, 2, {})
                .to(list[i], {
                    opacity: 0,
                    duration: 0.5,
                });
        }
    }
}
