import gsap from 'gsap';

export default new class Pageloader {
    constructor() {
        const el = `
            <div class="progress progress--center pageloader">
                <div class="progress__bar pageloader__bar"></div>
            </div>
        `;

        let $el = document.createElement('DIV');
        $el.innerHTML = el.trim();
        $el = $el.firstChild;

        document.body.appendChild($el);

        this.$el = $el;
    }

    start() {
        gsap.set(this.$el.querySelector('.pageloader__bar'), { width: 0, autoAlpha: 1, top: -4 });
        this.goTo(50);
    }

    goTo(percentage) {
        gsap.to(this.$el, { top: 0, autoAlpha: 1, duration: 0.1 });
        gsap.killTweensOf(this.$el.querySelector('.pageloader__bar'));
        gsap.to(this.$el.querySelector('.pageloader__bar'), {
            width: `${percentage}%`,
            ease: 'power4.easeOut',
            duration: 1,
        });
    }

    end(callback) {
        gsap.killTweensOf(this.$el.querySelector('.pageloader__bar'));
        gsap.to(this.$el.querySelector('.pageloader__bar'), {
            width: '100%',
            duration: 0.5,
            onComplete: () => {
                if (typeof callback === 'function') {
                    callback();
                }
            },
        });
    }

    hide() {
        gsap.to(this.$el.querySelector('.pageloader__bar'), { autoAlpha: 0, duration: 0.2 });
    }
}();
